<template>
  <div class="home">
    <div class="content">
      <div class="center">
        <!--<div class="time">{{areaname}} {{time}}</div>-->
        <div style="display:flex;justify-content:space-between;">
          <ul class="tab">
            <template v-for="(item, i) in tabList" >
              <li v-if="i != 1 && i!=3 && i!=4" @click="tabChange(i)" :class="i === tabIndex ? 'cur' : ''" :key="'tab' + i">{{item}}</li>
            </template>
            <!--<li @click="tabChange(i)" :class="i === tabIndex ? 'cur' : ''" v-for="(item, i) in tabList" :key="i">{{item}}</li>-->
            <li @click="goBack">返回</li>
          </ul>
          <div class="time">
            {{areaname}}  {{time}}
          </div>
        </div>
        <hr>
        <div style="display:flex;"> 
          <CustomCon :w="720" :showTitle="false" style="width:100%;" >
            <div class="map_con">
              <div class="point_menu">
                <div @mousemove="mousemoveHandle($event, point)" @mouseleave="mouseleaveHandle"  v-for="(point, pindex) in pointList" :key="point.name" @click="scalePoint(point, pindex)">
                  {{pindex +1}}.{{point.name}}
                </div>
                <div class="tip" :style="{left: pos.x + 'px', top: pos.y + 'px'}" v-show="tabIndex != 3 && tabIndex != 4 && tabIndex != 5">
                      <span class="t">详细信息</span>
                      <div v-if="tabIndex == 0">
                        <span>负责人： {{area.contacts}}</span><br /><span>电话： {{area.tel}}</span><br /><span>人流量： {{area.number}}人</span>
                      </div>
                      <div v-if="tabIndex == 1 || tabIndex == 2">
                        <span>负责人： {{area.contacts}}</span><br /><span>电话： {{area.tel}}</span>
                      </div>
                      <div v-if="tabIndex == 6">
                        <span>所在社区： {{area.cover}}</span><br /><span>负责人： {{area.leader}}</span><br /><span>联系电话： {{area.tel}}</span><br /><span>常住人口： {{area.number}}人</span>
                      </div>
                      <div v-if="tabIndex == 7">
                        <div v-for="(t, i) in area.leader" :key="i">
                          {{t.type == 1 ? '组长' : t.type == 2 ? '常务副组长' : '副组长'}}： {{t.name}} {{t.phone}}
                        </div>
                      </div>
                  </div>
              </div>
              <baidu-map-view ref="map"  style="height: 855px; flex: 1;" :optionType="'map2'"></baidu-map-view>
            </div>
          </CustomCon>
          <keep-alive>
            <component v-bind:is="currentTabComponent"></component>
          </keep-alive>
          <!--<Right />-->
        </div>
      </div>     
    </div>
  </div>
</template>

<script>
import {e1Option, pieOption, Lineoption} from '../data/home'
import BaiduMapView from '../components/baiduMap.vue'
import {formatDate} from '../utils/formatDate'
import CustomCon from '../components/custom-con.vue'
//import Left from './components/left.vue'
import Right from './components/right2.vue'
import VaccinateRight from './components/Vaccinate.vue'

export default {
  name: 'HomeView',
  components: {
    BaiduMapView,
    CustomCon,
    Right,
    VaccinateRight
  },
  data() {
    return {
      tabList: ['人流密集点', '隔离场所', '物资点', '交通站点', '跨境转运点', '哨点', '核酸检测点', '疫苗接种点', '流程图'],
      tabIndex: Number(this.$route.query.tabIndex),
      e1Options: e1Option,
      e3Options: pieOption,
      e7Options: Lineoption,
      config: {
        data: [],
        columnWidth: [650],
        carousel: 'single',
        rowNum: 3,
        oddRowBGC: 'rgba(0,0,0,0)',
        evenRowBGC: 'rgba(0,0,0,0)',
      },
      pos: {
          x: -10000,
          y: -10000
      },
      time: '',
      timer: null,
      pointList: [],
      watcherList: [],
      area: {},
      areaname: '',
      currentTabComponent: 'Right',
    }
  },
  created() {
    //let tabIndex = this.$route.query.tabIndex
    this.time = formatDate(new Date().getTime(), 'yyyy/MM/dd hh:mm:ss')
    this.timer = setInterval(() => {
      this.time = formatDate(new Date().getTime(), 'yyyy/MM/dd hh:mm:ss')
    }, 1000)
    this.areaname = this.$route.query.areaName;
    
    //this.tabChange(tabIndex);
    //this.getPlace();
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  mounted() {
    let tabIndex = this.$route.query.tabIndex
    this.currentTabComponent = 'Right';
    // if(this.optionType === 'map') {
    if(tabIndex == 5) {
        this.$refs.map.getWatcher()
        this.getWatcher();
    } else if(tabIndex == 2) {
         this.$refs.map.getMaterial();
         this.getMaterial()
    } else if(tabIndex == 1) {
         this.$refs.map.getIsolation();
         this.getIsolation()
    } else if(tabIndex == 6) {
         this.$refs.map.getHsStation()
         this.getHsStation()
    } else if(tabIndex == 7) {
         this.$refs.map.getVaccinate()
         this.getVaccinate();
         this.currentTabComponent = 'VaccinateRight';
    } else if(tabIndex == 0) {
         this.$refs.map.getPlace();
         this.getPlace();
    }else if(tabIndex == 4) {
         this.$refs.map.getTrain();
         this.getTrain();
    }else if(tabIndex == 3) {
         this.$refs.map.getTraffic();
         this.getTraffic();
    }
    
  },
  methods: {
    tabChange(i) {
      this.tabIndex = i;
      this.currentTabComponent = 'Right';
      if(i == 6) {
        this.$refs.map.getHsStation();
        this.getHsStation();
      } else if(i == 1) {
       this.$refs.map.getIsolation();
       this.getIsolation();
      } else if(i == 2) {
        this.$refs.map.getMaterial();
        this.getMaterial();
      } else if(i == 7) {
        this.$refs.map.getVaccinate();
        this.getVaccinate();
        this.currentTabComponent = 'VaccinateRight';
      } else if(i == 5) {
        this.$refs.map.getWatcher();
        this.getWatcher();
      } else if(i == 0) {
        this.$refs.map.getPlace();
        this.getPlace();
      }else if(i == 4) {
        this.$refs.map.getTrain();
        this.getTrain();
      }else if(i == 3) {
        this.$refs.map.getTraffic();
        this.getTraffic();
      } else if(i == 8) {
        this.$router.push({
          path: '/process',
          query: {
            areaName: this.$route.query.areaName
          }
        })
      }
    },
    scalePoint(point, pindex) {
      this.$refs.map.scalePoint(point, 16, pindex);
    },

    goBack() {
      this.$router.replace({
        path: '/',
        query: {
          tabIndex: this.tabIndex
        }
      })
    },
    mousemoveHandle(e, item) {
      this.pos.x = 140 ;
      this.pos.y = e.pageY - 134;
      this.area = item;
    },
    mouseleaveHandle() {
      this.pos = {
          x: -10000,
          y: -10000
      }
    },
    // 获取人员密集区
    getPlace() {
        this.$axios({
            url: 'http://test.tianjingcn.cn/api/place',
            method: 'post',
            data: {token: 'omJNpZEhZeHj1ZxFECKkP48B5VFbk1HP', area: this.$route.query.areaName}}
        ).then(res => {
            let data = res.data.data;
            let pointList = data.map(el => {
                return ({
                    name: el.name,
                    value: [el.lon, el.lat],
                    contacts: el.contacts,
                    tel: el.tel,
                    type: el.type,
                    number: el.number,
                    icon: {
                        url: el.default_path,
                        size: {
                            width: 32,
                            height: 32,
                        }
                    },
                    lat: el.lat,
                    lon: el.lon,
                    animation: '',
                    default_path: el.default_path,
                    selected_path: el.selected_path
                })
            })
            
            this.pointList = pointList;
        })
    },
    // 获取隔离场所
    getIsolation() {
        this.$axios({
            url: 'http://test.tianjingcn.cn/api/isolation',
            method: 'post',
            data: {token: 'omJNpZEhZeHj1ZxFECKkP48B5VFbk1HP', area: this.$route.query.areaName}}
        ).then(res => {
                let allPoint = [...res.data.data];
                let pointList = allPoint.map(el => {
                    return ({
                        name: el.name,
                        value: [el.lon, el.lat],
                        room: el.room,
                        used: el.used,
                        contacts: el.contacts,
                        address: el.address,
                        tel: el.tel,
                        type: el.type,
                        icon: {
                            url: el.default_path,
                            size: {
                                width: 32,
                                height: 32
                            }
                        },
                        lat: el.lat,
                        lon: el.lon,
                        animation: '',
                        default_path: el.default_path,
                        selected_path: el.selected_path
                    })
                })
               
                this.pointList = pointList;
        })
    },
    // 获取物资运输点
    getMaterial() {
        this.$axios({
            url: 'http://test.tianjingcn.cn/api/material',
            method: 'post',
            data: {token: 'omJNpZEhZeHj1ZxFECKkP48B5VFbk1HP', area: this.$route.query.areaName}}
        ).then(res => {
            let data = res.data.data;
            let pointList = data.map(el => {
                return ({
                    name: el.name,
                    value: [el.lon, el.lat],
                    contacts: el.contacts,
                    tel: el.tel,
                    icon: {
                        url: el.default_path,
                        size: {
                            width: 32,
                            height: 32
                        }
                    },
                    lat: el.lat,
                    lon: el.lon,
                    animation: '',
                    default_path: el.default_path,
                    selected_path: el.selected_path
                })
            })
            
            this.pointList = pointList;
        })
    },
    // 获取核酸检测点
    getHsStation() {
        this.$axios({
            url: 'http://test.tianjingcn.cn/api/hs_station',
            method: 'post',
            data: {token: 'omJNpZEhZeHj1ZxFECKkP48B5VFbk1HP', area: this.$route.query.areaName}}
        ).then(res => {
            let data = res.data.data;
            let pointList = data.map(el => {
                return ({
                    name: el.name,
                    value: [el.lon, el.lat],
                    code: el.code,
                    number: el.number,
                    cover: el.cover,
                    leader: el.leader,
                    tel: el.tel,
                    icon: {
                        url: el.default_path,
                        size: {
                            width: 32,
                            height: 32
                        }
                    },
                    lat: el.lat,
                    lon: el.lon,
                    animation: '',
                    default_path: el.default_path,
                    selected_path: el.selected_path
                })
            })
            
            this.pointList = pointList;
        })
    },
    //获取疫苗接种点
    getVaccinate() {
        this.$axios({
            url: 'http://test.tianjingcn.cn/api/vaccinate',
            method: 'post',
            data: {token: 'omJNpZEhZeHj1ZxFECKkP48B5VFbk1HP', area: this.$route.query.areaName}}
        ).then(res => {
            let data = res.data.data;
            let pointList = data.map(el => {
                return ({
                    name: el.name,
                    value: [el.lon, el.lat],
                    leader: el.leader,
                    icon: {
                        url: el.default_path,
                        size: {
                            width: 32,
                            height: 32,
                        }
                    },
                    lat: el.lat,
                    lon: el.lon,
                    animation: '',
                    default_path: el.default_path,
                    selected_path: el.selected_path
                })
            })
            this.pointList = pointList;
        })
    },
    // 获取哨点
    getWatcher() {
      this.$axios({
          url: 'http://test.tianjingcn.cn/api/watcher',
          method: 'post',
          data: {token: 'omJNpZEhZeHj1ZxFECKkP48B5VFbk1HP', area: this.$route.query.areaName}}
      ).then(res => {
          let data = res.data.data;

          let pointList = data.map(el => {
                return ({
                    name: el.name,
                    value: [el.lon, el.lat],
                    icon: {
                        url: el.default_path,
                        size: {
                            width: 32,
                            height: 32,
                        }
                    },
                    lat: el.lat,
                    lon: el.lon,
                    animation: '',
                    default_path: el.default_path,
                    selected_path: el.selected_path
                })
            })
            
            this.pointList = pointList;
      })
    },
    // 获取交通站点
    getTrain() {
      this.$axios({
          url: 'http://test.tianjingcn.cn/api/train',
          method: 'post',
          data: {token: 'omJNpZEhZeHj1ZxFECKkP48B5VFbk1HP', area: this.$route.query.areaName}}
      ).then(res => {
          let data = res.data.data;

          let pointList = data.map(el => {
                return ({
                    name: el.name,
                    value: [el.lon, el.lat],
                    icon: {
                        url: el.default_path,
                        size: {
                            width: 32,
                            height: 32,
                        }
                    },
                    lat: el.lat,
                    lon: el.lon,
                    animation: '',
                    default_path: el.default_path,
                    selected_path: el.selected_path
                })
            })
            
            this.pointList = pointList;
      })
    },
    // 获取跨境货车运输点
    getTraffic() {
      this.$axios({
          url: 'http://test.tianjingcn.cn/api/traffic',
          method: 'post',
          data: {token: 'omJNpZEhZeHj1ZxFECKkP48B5VFbk1HP', area: this.$route.query.areaName}}
      ).then(res => {
          let data = res.data.data;

          let pointList = data.map(el => {
                return ({
                    name: el.name,
                    value: [el.lon, el.lat],
                    icon: {
                        url: el.default_path,
                        size: {
                            width: 32,
                            height: 32,
                        }
                    },
                    lat: el.lat,
                    lon: el.lon,
                    animation: '',
                    default_path: el.default_path,
                    selected_path: el.selected_path
                })
            })
            
            this.pointList = pointList;
      })
    },

  }
}
</script>

<style lang="scss" scoped>
.home {
  padding: 10px;
  box-sizing: border-box;
  height: 100%;
  background-image: url("../assets/img/bg.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
  color: #00faf9;
  .header {
    height: 20px;
    background-image: url('../assets/img/h1.png');
    background-size: 100% 100%;
    position: relative;
    .h_t {
      height: 90px;
      width: 600px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      background-image: url('../assets/img/t1.png');
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .zh {
        font-size: 33px;
        font-weight: bold;
      }
      .en {
        font-size: 14px;
      }
    }
  }

  .content {
    display: flex;
    hr {
      border: none;
      height: 2px;
      background-color: #00faf9;
    }
    .left {
      margin-top: 15px;
      width: 550px;
      .top {
        height: 274px;
        display: flex;
        .l {
          width: 160px;
          margin-right: 14px;
          ul {
            margin-top: 40px;
            height: calc(100% - 50px);
            padding-left: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            li {
              font-size: 24px;
              font-weight: 700;
            }
          }
        }
        .r {
          flex: 1;
          ul {
            display: flex;
            margin-top: 36px;
            justify-content: center;
            li {
              padding: 0 10px;
              display: flex;
              flex-direction: column;
              .tit {
                font-size: 20px;
                font-weight: 600;
              }
              .item {
                font-size: 18px;
                font-weight: 600;
                line-height: 20px;
                line-height: 28px;
              }
            }
          }
        }
      }
      .center {
        width: 100%;
        margin-top: 15px;
        .e1 {
          height: 284px;
        }
        .ul1 {
          padding: 15px; 
          box-sizing: border-box;
          height: 252px;
          li {
            font-size: 20px;
            font-weight: 600;
            span {
              line-height: 38px;
              display: inline-block;
              &:nth-child(1) {
                width: 100px;
              }
            }
          }
        }
        .ul2 {
          padding: 15px; 
          box-sizing: border-box;
          height: 252px;
          li {
            font-size: 20px;
            font-weight: 600;
            &>span {
              line-height: 38px;
              display: inline-block;
              &:nth-child(1) {
                width: 130px;
              }
            }
            .secd {
              text-indent: 1em;
              line-height: 38px;
              span {
                display: inline-block;
                width: 140px;
              }
            }
          }
        }
      }
    }
    
    & > .center {
      flex: 1;
      padding: 0 15px;
      position: relative;
      .time {
        width:660px;
        font-size: 24px;
        font-weight: 700;
        text-align: center;
      }
      ul {
        display: flex;
        height: 36px;
        border: 1px solid #00f8f7;
        overflow: hidden;
        overflow-y: auto;
        li {
          height: 36px;
          width: 128px;
          line-height: 36px;
          text-align: center;
          font-weight: 700;
          font-size: 16px;
          border-right: 1px solid #00f8f7;
          cursor: pointer;
          &.cur {
            border: 1px solid #fff;
            box-sizing: border-box;
            background-color: #677fa0;
            color: #fff;
          }
        }
      }
      .map_con {
        display: flex;
        .point_menu {
          background-color: rgba($color: #000, $alpha: .3);
          border: 1px solid #00f8f7;
          padding: 4px;
          min-height: 200px;
          z-index: 200;
          width: 120px;
          height:848px;
          overflow: auto;
          & > div {
            line-height: 26px;
            //font-size: 18px;
            cursor: pointer;
            //position: relative;
            &:hover {
              border-bottom: 1px solid #fff;
              color: #fff;
              .tip {
                display: block;
              }
            }
          }
        }
      }
      
    }
    .right {
      margin-top: 15px;
      width: 550px;
      .rt1 {
        display: flex;
        justify-content: space-between;
        margin-top: 40px;
        padding-right: 15px;
        .e3 {
          height: 200px;
          flex: 1;
        }
        .e_title {
          font-size: 22px;
        }
        .time {
          font-size: 18px;
          line-height: 18px;
        }
        .item {
          font-size: 20px;
          font-weight: 700;
          span {
            line-height: 50px;
            &:nth-child(1) {
              display: inline-block;
              width: 100px;
              padding-right: 30px;
            }
            &:nth-child(2) {
              color: #cd5359;
              font-size: 24px;
            }
          }
        }
      }
      .rc {
        display: flex;
        .con {
          flex: 1;
          margin-top: 15px;
          &.c1 {
            margin-right: 7.5px;
          }
          &.c2 {
            margin-left: 7.5px;
          }
          .e4 {
            flex: 1;
            height: 140px;
            margin-top: 40px;
          }
        }
      }
    }
  }
}
.row-item {
  height: 10px !important;
}
.tip {
    border: 1px solid #00faf9;
    border-radius: 4px;
    background-color: #004259;
    padding: 15px;
    position: absolute;
    font-size: 18px;
    //left: 140px;
    //top: -45px;
    color: #00faf9;
    width: 300px;
    z-index: 9999999999;
    //display: none;
    padding-top: 20px;
    &::after {
        content: '';
        display: inline-block;
        height: 0px;
        width: 0px;
        border-left: 10px solid transparent;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-right: 10px solid #00faf9;
        position: absolute;
        left: -20px;
        top: 50px;
    }
    .t {
        width: 80%;
        text-align: center;
        text-align: center;
        display: inline-block;
        position: absolute;
        left: 50%;
        top: -15px;
        padding: 1px 6px;
        transform: translateX(-50%);
        border: 1px solid #00faf9;
        background-color: #004259;
    }
}
</style>
