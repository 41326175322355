<template>
    <div>
        <baidu-map class="map"  :scroll-wheel-zoom="true" @ready="initMap" map-type="BMAP_HYBRID_MAP" :zoom="14" @mousemove="syncPolyline" @click="paintPolyline" @rightclick="newPolyline">
            <bm-map-type :map-types="['BMAP_NORMAL_MAP', 'BMAP_HYBRID_MAP']" anchor="BMAP_ANCHOR_TOP_LEFT"></bm-map-type>
            <bm-scale anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-scale>
            <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
            <bm-marker v-for="(item, index) in placeList" :position="{lng: item.lon, lat: item.lat}" :key="'point' + index" :title="item.name" :icon="item.icon" :animation="item.animation" >
                <bm-label :content="index+1 + ''" :labelStyle="{color: 'red', fontSize : '24px', textAlign: 'right', width: '50px', background: 'none', fontWeight: 700, border:'none', zIndex: 999 }" :offset="{width: -50, height: 0}"/>
            </bm-marker>
            <bm-control>
                <p class="map_p">
                    <span v-for="(icon, index) in iconList" :key="'icon' + index" style="display:inline-block;">{{icon.name}}: <img :src="icon.default_path" style="width:24px;height:24px;vertical-align:middle;padding-right:8px;" /></span>
                </p>
            </bm-control>
            <bm-overlay >
                <div class="buttonList">
                    <button  @click="toggle('polyline')">{{ polyline.editing ? '保存绘制' : '开始绘制' }}</button>
                    <button  @click="clean()">清除图形</button>
                </div>
            </bm-overlay>
            <bm-polyline :path="path" v-for="(path, poindex) in polyline.paths" :key="'po' +poindex" strokeColor="red" strokeOpacity='1'></bm-polyline>
            <bm-polyline :path="drawPath" v-for="(drawPath, dwindex) in drawPolyline" :key="'podw' +dwindex" strokeColor="red" strokeOpacity='1'></bm-polyline>
        </baidu-map>
        <el-dialog 
            title="保存布控图"
            :visible.sync="dialogFormVisible"
            :before-close="handleClose"
        >
            <el-form :model="form" :rules="rules" ref="ruleForm" label-width="60px">
                <el-form-item label="标题" prop="title">
                    <el-input v-model="form.title" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="类型" prop="type">
                    <el-input v-model="form.type" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="说明" prop="content">
                    <el-input type="textarea" v-model="form.content" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="">
                <el-button @click="handleClose">取消</el-button>
                <el-button type="primary" @click="saveForm('ruleForm')">保存</el-button>
            </div>
        </el-dialog> 
    </div>
    
</template>

<script>
import { huiZhouMapJson, huiZhouEchartsOption3, huiZhouEchartsOption2, huiZhouEchartsOption4} from '../data/map';
import {deepClone} from '../utils/index';
import {bus} from '../utils/bus';

export default {
    name: 'BaiduMapView',
    components:{
    },
    data () {
        return {
            lineList: [],
            lineStr: "",
            tabIndex:0,
            huiZhouMapJson,
            huiZhouEcharts: null,
            huiZhouAreaEcharts: null,
            autoIndex: 0,
            areaMapJson: {
                type: 'FeatureCollection',
                features: []
            },
			timer: null,
            isInitArea: false,
            currentAreaCode: 0,
            mapStyle: {
                style: 'midnight'
            },
            placeList: [],
            map: null,
            markerPoint: [],
            huiZhouEchartsOption3: deepClone(huiZhouEchartsOption3),
            huiZhouEchartsOption2: deepClone(huiZhouEchartsOption2),
            huiZhouEchartsOption4,
            mapOption: {
                '芦洲镇': {
                    center: [114.635158, 23.351752],
                    name: 'luzhou',
                    zoom: 13
                },
                '横沥镇': {
                    center: [114.665095, 23.236246],
                    name: 'hengli',
                    zoom: 13
                },
                '汝湖镇': {
                    center: [114.52207, 23.201596],
                    name: 'luhu',
                    zoom: 13
                },
                '马安镇': {
                    center: [114.508346, 23.06445],
                    name: 'maan',
                    zoom: 14
                },
                '三栋镇': {
                    center: [114.455009,22.997408],
                    name: 'sandong',
                    zoom: 14
                },
                '小金口': {
                    center: [114.394207, 23.180074],
                    name: 'xiaojinkou',
                    zoom: 14
                },
                '江北': {
                    center: [114.425152,23.121972],
                    name: 'jiangbei',
                    zoom: 15
                },
                '水口': {
                    center: [114.524469, 23.124997],
                    name: 'shuikou',
                    zoom: 13
                },
                '桥东': {
                    center: [114.443775, 23.086759],
                    name: 'qiaodong',
                    zoom: 14
                },
                '桥西': {
                    center: [114.413656, 23.086795],
                    name: 'qiaoxi',
                    zoom: 15
                },
                '江南': {
                    center: [114.36533, 23.128839],
                    name: 'jiangnan',
                    zoom: 14
                },
                '龙丰': {
                    center: [114.362109, 23.092144],
                    name: 'longfeng',
                    zoom: 14
                },
                '河南岸': {
                    center: [114.43543, 23.051651],
                    name: 'henanan',
                    zoom: 14
                },
            },
            mapJson: null,
            option: null,
            areaList: [],
            iconList: [],
            polyline: {
                editing: false,
                paths: []
            },
            dialogFormVisible:false,
            form:{
                title: '',
                type: '',
                content: ''
            },
            rules:{
                title:[{required: true, message: '请输入布控图标题'}],
                type:[{required: true, message: '请输入布控图类型'}],
                content:[{required: true, message: '请输入布控图说明'}],
            },
            drawPolyline:[]
        }
    },
    created () {
        const regionName = this.$route.query.areaName;
        this.option = this.mapOption[regionName];
        let mapJson = this.huiZhouMapJson;
        const areaName = this.$route.query.areaName;
        if(areaName) {
            const features = this.huiZhouMapJson.features.filter(el => el.properties.name === regionName);
            mapJson = {
                "type": "FeatureCollection",
                "features": features
            }
        }
        this.mapJson = mapJson;
        this.lineStr = this.getLineStr();
        this.getPlace();
        this.getIcon();
    },
    methods: {
        initMap ({BMap, map}) {
            console.log(BMap, map);
            this.map = map;
            this.map.centerAndZoom(new BMap.Point(this.option.center[0],this.option.center[1]), this.option.zoom);
            this.drawPolygon();
        },
        // 绘制行政区划轮廓
        drawPolygon() {
            var E_JW = "170.672126, 39.623555;";        //东
            var EN_JW = "170.672126, 81.291804;";       //东北角
            var N_JW = "105.913641, 81.291804;";        //北
            var NW_JW = "-169.604276,  81.291804;";     //西北角
            var W_JW = "-169.604276, 38.244136;";       //西
            var WS_JW = "-169.604276, -68.045308;";     //西南角
            var S_JW = "114.15563, -68.045308;";        //南
            var SE_JW = "170.672126, -68.045308 ;";     //东南角
            var ply1 = new BMap.Polygon(this.lineStr + E_JW + SE_JW + S_JW + WS_JW + W_JW + NW_JW + N_JW + EN_JW + E_JW, {
                strokeColor: "none",
                fillColor: "rgba(0,0,0,0.8)",
                fillOpacity: 1,
                strokeOpacity: 0.5
            })
            this.map.addOverlay(ply1);
            let strs = this.lineStr.slice(0,this.lineStr.length-1);
            var ply = new BMap.Polygon(strs, {strokeWeight: 2, strokeColor: '#999', fillColor: ""});
            this.map.addOverlay(ply);
        },
        getLineStr () {
            const coordinates = this.mapJson.features[0].geometry.coordinates[0];
            let str = "";
            for (let j = 0; j < coordinates.length; j++) {
                const item = coordinates[j];
                // str += item[1] + ',' + item[0] + ';' ;
                // let lng = item[0] + 0.011 ;
                // let lat = item[1] + 0.003;
                let lng = item[0];
                let lat = item[1];
                str += lng + ',' + lat + ';' ;
            }
            return str;
        },
        // 获取地图图标
        getIcon () {
             this.$axios({
                url: 'http://test.tianjingcn.cn/api/icon',
                method: 'post',
                data: {token: 'omJNpZEhZeHj1ZxFECKkP48B5VFbk1HP'}}
            ).then(res => {
                let data = res.data.data;
                this.iconList = data;
            })
        },
        // 获取人员密集区
        getPlace() {
            this.tabIndex = 0;
            this.$axios({
                url: 'http://test.tianjingcn.cn/api/place',
                method: 'post',
                data: {token: 'omJNpZEhZeHj1ZxFECKkP48B5VFbk1HP', area: this.$route.query.areaName}}
            ).then(res => {
                let data = res.data.data;
                let pointList = data.map(el => {
                    return ({
                        name: el.name,
                        value: [el.lon, el.lat],
                        contacts: el.contacts,
                        tel: el.tel,
                        type: el.type,
                        number: el.number,
                        icon: {
                            url: el.default_path,
                            size: {
                                width: 32,
                                height: 32,
                            }
                        },
                        lat: el.lat,
                        lon: el.lon,
                        animation: '',
                        default_path: el.default_path,
                        selected_path: el.selected_path
                    })
                })
                this.placeList = pointList;
                this.makePoint();
            })
        },
        // 获取隔离场所
        getIsolation() {
            this.tabIndex = 1;
            this.$axios({
                url: 'http://test.tianjingcn.cn/api/isolation',
                method: 'post',
                data: {token: 'omJNpZEhZeHj1ZxFECKkP48B5VFbk1HP', area: this.$route.query.areaName}}
            ).then(res => {
                    let allPoint = [...res.data.data];
                    console.log('data', allPoint);
                    let pointList = allPoint.map(el => {
                        return ({
                            name: el.name,
                            value: [el.lon, el.lat],
                            room: el.room,
                            used: el.used,
                            contacts: el.contacts,
                            address: el.address,
                            tel: el.tel,
                            type: el.type,
                            icon: {
                                url: el.default_path,
                                size: {
                                    width: 32,
                                    height: 32
                                }
                            },
                            lat: el.lat,
                            lon: el.lon,
                            animation: '',
                            default_path: el.default_path,
                            selected_path: el.selected_path
                        })
                    })
                    this.placeList = pointList;
                    this.makePoint();
            })
        },
        // 获取物资运输点
        getMaterial() {
            this.tabIndex = 2;
            this.$axios({
                url: 'http://test.tianjingcn.cn/api/material',
                method: 'post',
                data: {token: 'omJNpZEhZeHj1ZxFECKkP48B5VFbk1HP', area: this.$route.query.areaName}}
            ).then(res => {
                let data = res.data.data;
                let pointList = data.map(el => {
                    return ({
                        name: el.name,
                        value: [el.lon, el.lat],
                        contacts: el.contacts,
                        tel: el.tel,
                        icon: {
                            url: el.default_path,
                            size: {
                                width: 32,
                                height: 32
                            }
                        },
                        lat: el.lat,
                        lon: el.lon,
                        animation: '',
                        default_path: el.default_path,
                        selected_path: el.selected_path
                    })
                })
                this.placeList = pointList;
                this.makePoint();
            })
        },
        // 获取核酸检测点
        getHsStation() {
            this.tabIndex = 6;
            this.$axios({
                url: 'http://test.tianjingcn.cn/api/hs_station',
                method: 'post',
                data: {token: 'omJNpZEhZeHj1ZxFECKkP48B5VFbk1HP', area: this.$route.query.areaName}}
            ).then(res => {
                let data = res.data.data;
                let pointList = data.map(el => {
                    return ({
                        name: el.name,
                        value: [el.lon, el.lat],
                        code: el.code,
                        number: el.number,
                        cover: el.cover,
                        icon: {
                            url: el.default_path,
                            size: {
                                width: 32,
                                height: 32
                            }
                        },
                        lat: el.lat,
                        lon: el.lon,
                        animation: '',
                        default_path: el.default_path,
                        selected_path: el.selected_path
                    })
                })
                this.placeList = pointList;
                this.makePoint();
            })
        },
        //获取疫苗接种点
        getVaccinate() {
            this.tabIndex = 7;
            this.$axios({
                url: 'http://test.tianjingcn.cn/api/vaccinate',
                method: 'post',
                data: {token: 'omJNpZEhZeHj1ZxFECKkP48B5VFbk1HP', area: this.$route.query.areaName}}
            ).then(res => {
                let data = res.data.data;
                let pointList = data.map(el => {
                    return ({
                        name: el.name,
                        value: [el.lon, el.lat],
                        leader: el.leader,
                        icon: {
                            url: el.default_path,
                            size: {
                                width: 32,
                                height: 32,
                            }
                        },
                        lat: el.lat,
                        lon: el.lon,
                        animation: '',
                        default_path: el.default_path,
                        selected_path: el.selected_path
                    })
                })
                this.placeList = pointList;
                this.makePoint();
            })
        },
        //获取哨点
        getWatcher() {
            this.tabIndex = 5;
            this.$axios({
                url: 'http://test.tianjingcn.cn/api/watcher',
                method: 'post',
                data: {token: 'omJNpZEhZeHj1ZxFECKkP48B5VFbk1HP', area: this.$route.query.areaName}}
            ).then(res => {
                let data = res.data.data;
                let pointList = data.map(el => {
                    return ({
                        name: el.name,
                        value: [el.lon, el.lat],
                        icon: {
                            url: el.default_path,
                            size: {
                                width: 32,
                                height: 32,
                            }
                        },
                        lat: el.lat,
                        lon: el.lon,
                        animation: '',
                        default_path: el.default_path,
                        selected_path: el.selected_path
                    })
                })
                
                this.placeList = pointList;
                this.makePoint();
            })
        },
        // 获取跨境货车运输点
        getTrain() {
            this.tabIndex = 4;
            this.$axios({
                url: 'http://test.tianjingcn.cn/api/train',
                method: 'post',
                data: {token: 'omJNpZEhZeHj1ZxFECKkP48B5VFbk1HP', area: this.$route.query.areaName}}
            ).then(res => {
                let data = res.data.data;
                console.log(data);
                let pointList = data.map(el => {
                    return ({
                        name: el.name,
                        value: [el.lon, el.lat],
                        icon: {
                            url: el.default_path,
                            size: {
                                width: 32,
                                height: 32,
                            }
                        },
                        lat: el.lat,
                        lon: el.lon,
                        animation: '',
                        default_path: el.default_path,
                        selected_path: el.selected_path
                    })
                })
                this.placeList = pointList;
                this.makePoint();
            })
        },
        //  获取交通站点
        getTraffic() {
            this.tabIndex = 3;
            this.$axios({
                url: 'http://test.tianjingcn.cn/api/traffic',
                method: 'post',
                data: {token: 'omJNpZEhZeHj1ZxFECKkP48B5VFbk1HP', area: this.$route.query.areaName}}
            ).then(res => {
                let data = res.data.data;
                console.log(data);
                let pointList = data.map(el => {
                    return ({
                        name: el.name,
                        value: [el.lon, el.lat],
                        icon: {
                            url: el.default_path,
                            size: {
                                width: 32,
                                height: 32,
                            }
                        },
                        lat: el.lat,
                        lon: el.lon,
                        animation: '',
                        default_path: el.default_path,
                        selected_path: el.selected_path
                    })
                })
                this.placeList = pointList;
                this.makePoint();
            })
        },
        makePoint() {
            let points = [];
            if (this.placeList.length > 0) {
                for(let i = 0; i < this.placeList.length; i++) {
                    points.push({lat: this.placeList[i].lat, lng: this.placeList[i].lon});
                }
                this.markerPoint = points;
            }
        },
        scalePoint(point, zoom, pindex) {
            this.placeList.forEach(function(el) {
                el.icon = {
                    url: el.default_path,
                    size: {
                        width: 32,
                        height: 32
                    }
                };
                el.animation = '';
            })
            this.placeList[pindex].icon = {
                url: point.selected_path,
                size: {
                    width: 32,
                    height: 32
                }
            };
            console.log('placeList', this.placeList);
            this.placeList[pindex].animation = 'BMAP_ANIMATION_BOUNCE';
            this.map.centerAndZoom(new BMap.Point(point['value'][0], point['value'][1]), zoom);
        },
        toggle (name) {
            console.log('toggle');
            if (this[name].editing && this[name].paths.length > 0) {
                //console.log('polyline', this.polyline);
                this.dialogFormVisible = true;
            }
            this[name].editing = !this[name].editing;
        },
        syncPolyline (e) {
            if (!this.polyline.editing) {
                return
            }
            const {paths} = this.polyline
            if (!paths.length) {
                return
            }
            const path = paths[paths.length - 1]
            if (!path.length) {
                return
            }
            if (path.length === 1) {
                path.push(e.point)
            }
            this.$set(path, path.length - 1, e.point)
        },
        newPolyline (e) {
            console.log(e)
            if (!this.polyline.editing) {
                return
            }
            const {paths} = this.polyline
            if(!paths.length) {
                paths.push([])
            }
            const path = paths[paths.length - 1]
            path.pop()
            if (path.length) {
                paths.push([])
            }
        },
        paintPolyline (e) {
            console.log('paintPolyline');
            if (!this.polyline.editing) {
                return
            }
            const {paths} = this.polyline
            !paths.length && paths.push([])
            paths[paths.length - 1].push(e.point)
        },
        handleClose() {
            this.$confirm('此操作将丢失数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.dialogFormVisible = false;
                this.polyline.paths = [];
            }).catch(() => {});
        },
        saveForm(formName) {
            const that = this;
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (that.polyline.paths.length < 2) {
                        that.$alert('缺少关键数据', '标题名称', {
                            confirmButtonText: '确定',
                            callback: () => {
                                that.toggle('polyline')
                                that.dialogFormVisible = false;
                            }
                        });
                    }
                    that.$axios({
                        url: 'http://test.tianjingcn.cn/api/drawing_save',
                        method: 'post',
                        data: {
                            token: 'omJNpZEhZeHj1ZxFECKkP48B5VFbk1HP', 
                            area: that.$route.query.areaName,
                            title: that.form.title,
                            type: that.form.type,
                            content: that.form.content,
                            points: JSON.stringify(that.polyline.paths)
                        }
                    }).then(res => {
                        let data = res.data;
                        if (data.status == 200) {
                            that.$message({
                                message: '保存成功',
                                type: 'success'
                            });
                            that.polyline.paths = [];
                            bus.$emit('updatedrawing');
                        }else{
                            that.$message.error('保存失败');
                        }
                    })
                    that.dialogFormVisible = false;
                }else{
                    return false;
                }
            })
        },
        drawPolylines(path) {
            this.drawPolyline = path;
        },
        clean() {
            this.drawPolyline = [];
            this.polyline.paths = [];
            this.$message({
                message: '清除成功',
                type: 'success'
            });
        }
    },
    watch: {
        placeList() {
            this.map.centerAndZoom(new BMap.Point(this.option.center[0],this.option.center[1]), this.option.zoom);
        }
    }
}
</script>

<style lang="scss" scoped>
.map {
  width: 100%;
  height: 100%;
}

.map_p {
    background: #fff;
    padding: 10px;
    margin-left: 100px;
    
}


.buttonList {
    position: absolute;
    right: 10px;
    bottom: 10px;
    button {
        background-color: #3949ab;
        padding: 10px;
        border: 0;
        border-radius: 2px;
        color: #fff;
        font-size: 18px;
        margin: 10px;
        transition: all .4s cubic-bezier(.25,.8,.25,1);
        cursor: pointer;
    }
}

::v-deep .Bmap_cpyCtrl {
    display: none;
}
::v-deep .anchorBL {
    display: none;
}
::v-deep .BMap_scaleTxt {
    color: #fff !important;
}

</style>