<template>
     <div class="right_con">
        <CustomCon :w="720" :showTitle="false" style="width:100%;height:106px;text-align:center; margin-bottom: 10px;" >
            <div class="r2">
                <!--<div class="r_top">
                    <div class="r_title">新增数据</div>
                    <div class="r_time">数据截至{{time}}</div>
                </div>-->
                <div class="content">
                    <div>
                        <p class="t1">累计接种人数</p>
                        <p class="t_yellow r_tt">{{vacData.totle}}</p>
                    </div>
                    <div>
                        <p class="t1">当日接种总数</p>
                        <p class="t_green r_tt">{{vacData.yesterday}}</p>
                    </div>
                    <div>
                        <p class="t1">当日可接种人数</p>
                        <p class="t_red r_tt">{{vacData.today}}</p>
                    </div>
                </div>
            </div>
        </CustomCon>

        <CustomCon :w="720" :showTitle="false" style="width:100%;height:106px;text-align:center;margin-bottom: 10px;" >
            <div class="r2">
                <!--<div class="r_top">
                    <div class="r_title">新增数据</div>
                    <div class="r_time">数据截至{{time}}</div>
                </div>-->
                <div class="content">
                    <div>
                        <p class="t1">当日接种计划</p>
                        <p class="t_yellow r_tt">{{vacData.today_plan}}</p>
                    </div>
                    <div>
                        <p class="t1">明日接种计划</p>
                        <p class="t_green r_tt">{{vacData.tomorrow_plan}}</p>
                    </div>
                    <div>
                        <p class="t1">可用疫苗数</p>
                        <p class="t_red r_tt">{{vacData.usable}}</p>
                    </div>
                </div>
            </div>
        </CustomCon>

        <CustomCon :w="720" :showTitle="false" style="width:100%;height:106px;text-align:center;margin-bottom: 10px;" >
            <div class="r2">
                <!--<div class="r_top">
                    <div class="r_title">新增数据</div>
                    <div class="r_time">数据截至{{time}}</div>
                </div>-->
                <div class="content">
                    <div>
                        <p class="t1">生成场所码</p>
                        <p class="t_yellow r_tt">{{divisionData.division_num}}</p>
                    </div>
                    <div>
                        <p class="t1">今日打卡数</p>
                        <p class="t_green r_tt">{{divisionData.log_today_num}}</p>
                    </div>
                    <div>
                        <p class="t1">累计打卡数</p>
                        <p class="t_red r_tt">{{divisionData.log_total_num}}</p>
                    </div>
                </div>
            </div>
        </CustomCon>

        <div class="quyun">
            <div class="search">
                <el-date-picker
                        v-model="selectTime"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="选择日期">
                    </el-date-picker>
                <el-input placeholder="请输入内容" v-model="seletcTitle" class="input-with-select">
                    <el-button slot="append" @click="search" icon="el-icon-search"></el-button>
                </el-input>
            </div>
            <template v-if="tuList.length > 0"> 
                <div class="quyun_swiper" style="">
                    <swiper
                        :options="swiperOption"
                        @swiper="onSwiper"
                        @slideChange="onSlideChange"
                    >
                        <swiper-slide v-for="(tu, tindex) in tuList" :key="'tu' + tindex"><div @click="changeTu(tu)"><img :class="tuid === tu.id ? 'active' : ''" :src="tu.image" /><p class="swiper_p">{{tu.time}}</p></div></swiper-slide>
                    </swiper>
                    <div class="swiper-button-prev" slot="button-prev"></div>
                    <div class="swiper-button-next" slot="button-next"></div>
                </div>
                <div class="quyun_body">
                    <p class="quyun_body_title">
                        {{tuDetail.title}} <button @click="deleteDrawing" v-show="tuid != null">删除</button>
                    </p>
                    <p class="quyun_body_time">
                        {{tuDetail.time}} <span>{{tuDetail.type}}</span>
                    </p>
                    <div class="quyun_body_content">
                        <p>{{tuDetail.content}}</p>
                    </div>
                </div>
            </template>
            <template v-else>
                <p class="none">暂无数据</p>
            </template>
        </div>
        <CustomCon :w="720" :showTitle="false" style="width:100%;height:170px;text-align:center;" >
            <div class="zhibo">
                <p class="zotitle">现场直播</p>
                <div class="list">
                    <div @click="zhiboClick(zhibo)" v-for="(zhibo, index) in zhiboList " :key="'zhibo' + index">
                        <img :src="zhibo.img" />
                    </div>
                </div>
            </div>
        </CustomCon>
        <el-dialog :visible.sync="dialogTableVisible">
            <CustomCon :w="150" class="l" tText="现场直播" style="padding:50px 20px 20px 20px ;">
                <video :src="zhiboVideo"
                        webkit-playsinline="true"
                        controls="controls"
                        playsinline="true"
                        x-webkit-airplay="allow"
                        x5-playsinline
                        style="width: 100%;height:100%;"
                        @play="onPlayerPlay"
                        @pause="onPlayerPause"
                        ref="video"
                ></video>
            </CustomCon>
        </el-dialog>
     </div>
</template>

<script>
import CustomCon from '../../components/custom-con.vue'
import {formatDate} from '../../utils/formatDate'
import { bus } from '../../utils/bus'
export default {
    name: 'RightCon',
    components: {
        CustomCon,
    },
    data() {
        return {
            seletcTitle: '',
            time: '',
            tuid: null,
            selectTime: '',
            tuList:[],
            swiperOption: {
                slidesPerView: 5,
                navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
                },
                loop: false
            },
            zhiboList: [
                {
                    img: require("../../assets/img/zhibo.png"),
                    video: require('../../assets/video/video1.mp4')
                },
                {
                    img: require("../../assets/img/zhibo.png"),
                    video: require('../../assets/video/video2.mp4')
                },
                {
                    img: require("../../assets/img/zhibo.png"),
                    video: require('../../assets/video/video3.mp4')
                },
            ],
            dialogTableVisible: false,
            zhiboVideo: null,
            tuDetail: null,
            vacData:{
                totle: 0,
                yesterday:0,
                today:0,
                today_plan:0,
                tomorrow_plan:0,
                usable:0
            },
            divisionData: {
                id:0,
                area: '',
                division_num: 1,
                log_total_num: 3,
                log_today_num: 2
            }
        }
    },
    mounted() {
        this.time = formatDate(new Date().getTime(), 'yyyy/MM/dd hh:mm:ss')
        this.getDrawing();
        this.getVacData();
        this.getDivisionData();
        bus.$on('updatedrawing', () => {
            this.getDrawing();
        })
    },
    methods: {
        onSwiper () {
            console.log('onSwiper')
        },
        onSlideChange () {
            console.log('onSlideChange')
        },
        zhiboClick(zhibo) {
            this.zhiboVideo = zhibo.video;
            this.dialogTableVisible = true;
        },
        
        // 播放回调
        onPlayerPlay(player) {
            // this.globalSetting = true
            console.log("player play!", player);
            // document.getElementsByClassName("vjs-control-bar").style.display = "block";
            // document.getElementsByClassName("vjs-control-bar").style.display = "block";
        },

        // 暂停回调
        onPlayerPause(player) {
            // this.globalSetting.controls = false;
            console.log("player pause!", player);
            // var video = document.getElementById("video");
            // video.controls=false;
            // document.getElementsByClassName("vjs-control-bar").style.display = "none";
        },
        // 获取布控图
        getDrawing() {
            this.$axios({
                url: 'http://test.tianjingcn.cn/api/drawing',
                method: 'post',
                data: {token: 'omJNpZEhZeHj1ZxFECKkP48B5VFbk1HP', area: this.$route.query.areaName}}
            ).then(res => {
                let data = res.data.data;
                
                let pointList = data.map(el => {
                    return ({
                        id: el.id,
                        image: require("../../assets/img/img111.png"),
                        time: el.time,
                        title: el.title,
                        type: el.type,
                        content: el.content,
                        points: el.points
                    })
                })
                this.tuList = pointList;
                this.tuDetail = pointList ?  pointList[0] : null;
            })
        },
        // 获取基本数据
        getVacData() {
            this.$axios({
                url: 'http://test.tianjingcn.cn/api/vac_data',
                method: 'post',
                data: {token: 'omJNpZEhZeHj1ZxFECKkP48B5VFbk1HP', area: this.$route.query.areaName}}
            ).then(res => {
                // let data = res.data.data[0];
                let data = res.data.data;
                if (data) {
                    this.vacData = data;
                }
                
            })
        },
        // 获取场所码等
        getDivisionData() {
            this.$axios({
                url: 'http://test.tianjingcn.cn/api/division_data',
                method: 'post',
                data: {token: 'omJNpZEhZeHj1ZxFECKkP48B5VFbk1HP', area: this.$route.query.areaName}}
            ).then(res => {
                // let data = res.data.data[0];
                let data = res.data.data;
                if (Array.isArray(data)) {
                    data.forEach(divs => {
                        if (divs.area == this.$route.query.areaName) {
                            this.divisionData = divs;
                        }
                    });
                }
            })
        },

        changeTu(tu) {
            this.tuDetail = tu;
            this.tuid = tu.id;
            this.$parent.$refs.map.drawPolylines(JSON.parse(tu.points));
        },
        search() {
            this.$axios({
                url: 'http://test.tianjingcn.cn/api/drawing',
                method: 'post',
                data: {
                    token: 'omJNpZEhZeHj1ZxFECKkP48B5VFbk1HP', 
                    area: this.$route.query.areaName,
                    title: this.seletcTitle,
                    time: this.selectTime
                }
            }).then(res => {
                let data = res.data.data;
                let pointList = data.map(el => {
                    return ({
                        id: el.id,
                        image: require("../../assets/img/img111.png"),
                        time: el.time,
                        title: el.title,
                        type: el.type,
                        content: el.content,
                        points: el.points
                    })
                })
                this.tuList = pointList;
                this.tuDetail = pointList ?  pointList[0] : null;
            }) 
        },
        deleteDrawing() {
            let tuid = this.tuid;
            let that = this;
            if (tuid) {
                this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    that.$axios({
                        url: 'http://test.tianjingcn.cn/api/drawing_del',
                        method: 'post',
                        data: {token: 'omJNpZEhZeHj1ZxFECKkP48B5VFbk1HP', id: tuid}}
                    ).then(res => {
                        let data = res.data;
                        if (data.status == 200) {
                            that.$message({
                                type: 'success',
                                message: '删除成功!'
                            });
                            bus.$emit('updatedrawing');
                        }else{
                            that.$message.error('删除失败');
                        }
                    })
                }).catch(() => {        
                });
            }else{
                this.$message.error('删除失败');
            }
            console.log(this.tuid);
        }
    }
}
</script>
<style lang="scss" scoped>
.right_con {
    width: 1020px;
    height: 100%;
    margin: 0px 5px 0px 10px;
    .r2 {
        padding: 25px 20px 20px 20px;
        .r_top {
            .r_title {
                font-size: 20px;
                font-weight: 600;
            }
            .r_time {
                font-size: 12px;
                margin: 10px;
            }
        }
        .content {
            display: flex;
            justify-content: space-around;
            div {
                width: 180px;
            }
            .r_tt {
                font-size: 25px;
                padding: 10px;
            }
            .t1 {
                color: #fff;
            }
            .t_yellow {
                color: yellow;
            }
            .t_green {
                color: green;
            }
            .t_red {
                color: red;
            }
        }
    }
    .zhibo {
        .zotitle {
            text-align: left;
            font-size: 24px;
            font-weight: 600;
            padding: 15px;
        }
        .list {
            display: flex;
            justify-content: space-around;
            img {
                border: 1px solid #fff;
            }
        }
    }
    .quyun {
        width: 100%;
        height: 330px;
        border: 1px solid #028ea2;
        margin-bottom: 10px;
        .search {
            display: flex;
        }
        .quyun_swiper {
            position:relative;
            img {
                border: 1px solid #028ea2;
            }
            .active {
                box-shadow: 0px 0px 10px #00faf9;
                border: 1px solid #00faf9;
            }
        }
        .quyun_body {
            padding:0 20px 10px 20px;
            .quyun_body_title {
                font-size: 20px;
                font-weight: 600;
                padding-left:10px;
                button {
                    background-color: #3949ab;
                    padding: 6px 6px 6px 12px;
                    border: 0;
                    border-radius: 2px;
                    font-size: 14px;
                    font-weight: 600;
                    letter-spacing:5px;
                    color: red;
                    margin: 2px;
                    transition: all .4s cubic-bezier(.25,.8,.25,1);
                    cursor: pointer;
                    float: right;
                }
            }
            .quyun_body_time {
                font-size: 12px;
                padding: 6px 0;
                span {
                    color: red;
                }
            }
            .quyun_body_content {
                color: #fff;
                font-weight: 600;
                font-size: 15px;
                line-height: 30px;
            }
        }
        .none {
            font-size: 30px;
            text-align: center;
            color: #00faf9;
            margin: 50px;
        }
    }
}

.swiper-container {
    width: 600px;
    height: 120px;
    margin: 20px auto;
}
.swiper_p {
    font-size: 12px;
    color: #fff;
    padding-left: 17px;
}
::v-deep .swiper-wrapper {
    padding-left: 10px;
}
::v-deep .el-dialog__header {
  padding: 0;
  
}
::v-deep .el-dialog__body {
  padding: 0;
  /*background-color: rgba(3, 27, 63, 0.8);*/
  background-color: #004259;
  color:#00faf9;
}
::v-deep .el-dialog__headerbtn {
  z-index: 9999;
}
::v-deep .el-input__inner {
    border-radius: 0;
    border: 1px solid #00faf9 !important;
    background-color: #003650;
    color: #00faf9;
}
::v-deep .el-input-group__append {
    border-radius: 0;
    border: 1px solid #00faf9 !important;
    background-color: #003650;
    color: #00faf9;
}
</style>