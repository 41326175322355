<template>
     <div class="right_con">
        <CustomCon :w="720" :showTitle="false" style="width:100%;height:180px;text-align:center; margin-bottom: 10px;" >
            <div class="r2">
                <div class="r_top">
                    <div class="r_title">新增数据</div>
                    <div class="r_time">数据截至{{time}}</div>
                </div>
                <div class="content">
                    <div class="w50">
                        <div class="row">
                            <p class="t1 w50">累计接种人数</p>
                            <p class="t_yellow r_tt w50">{{vacData.totle}}</p>
                        </div>
                        <div class="row">
                            <p class="t1 w50">明日接种计划</p>
                            <p class="t_green r_tt w50">{{vacData.tomorrow_plan}}</p>
                        </div>
                        <div class="row">
                            <p class="t1 w50">当日接种计划</p>
                            <p class="t_red r_tt w50">{{vacData.today_plan}}</p>
                        </div>
                        
                    </div>
                    <div style="width:50%;">
                        <div class="row">
                            <p class="t1 w50">当日可接种人数</p>
                            <p class="t_yellow r_tt w50">{{vacData.today}}</p>
                        </div>
                        
                        <div class="row">
                            <p class="t1 w50">当日接种总数</p>
                            <p class="t_green r_tt w50">{{vacData.yesterday}}</p>
                        </div>
                        <div class="row">
                            <p class="t1 w50">当日可用疫苗</p>
                            <p class="t_red r_tt w50">{{vacData.usable}}</p>
                        </div>
                    </div>
                    
                </div>
            </div>
        </CustomCon>

        <CustomCon :w="204" :showTitle="false" style="width:100%;height:215px;text-align:center; margin-bottom: 10px;">
            <Echarts ref="barOption" style="height: 200px" id="barOption" :options="barOption" />
        </CustomCon>

        <CustomCon :w="330" :showTitle="false" style="width:100%;height:215px;text-align:center; margin-bottom: 10px;">
            <Echarts ref="e7" style="height: 200px" :initStart="false" id="e7" :options="e7Options" />
        </CustomCon>

        <CustomCon :w="330" :showTitle="false" style="width:100%;height:215px;text-align:center;">
            <Echarts ref="barOption3" style="height: 200px" :initStart="false" id="barOption3" :options="barOption3" />
        </CustomCon>
     </div>
</template>

<script>
import CustomCon from '../../components/custom-con.vue'
import {formatDate} from '../../utils/formatDate'
import {Lineoption2, barOption, barOption3} from '../../data/home'
import Echarts from '../../components/echarts.vue'

export default {
    name: "VaccinateRight",
    components: {
        CustomCon,
        Echarts
    },
    data() {
        return {
            time: '',
            vacData:{
                totle: 0,
                yesterday:0,
                today:0,
                today_plan:0,
                tomorrow_plan:0,
                usable:0
            },
            barOption: barOption,
            barOption3: barOption3,
            e7Options: JSON.parse(JSON.stringify(Lineoption2)),
        }
    },
    mounted() {
        this.time = formatDate(new Date().getTime(), 'yyyy/MM/dd hh:mm:ss')
        this.getVacData();
        this.getVacList();
    },
    methods: {
        // 获取基本数据 
        getVacData() {
            this.$axios({
                url: 'http://test.tianjingcn.cn/api/vac_data',
                method: 'post',
                data: {token: 'omJNpZEhZeHj1ZxFECKkP48B5VFbk1HP', area: this.$route.query.areaName}}
            ).then(res => {
                // let data = res.data.data[0];
                let data = res.data.data;
                if (data) {
                    this.vacData = data;
                }
                
            })
        },
        // 获取数据列表
        getVacList() {
            this.$axios({
                url: 'http://test.tianjingcn.cn/api/vac_list',
                method: 'post',
                data: {token: 'omJNpZEhZeHj1ZxFECKkP48B5VFbk1HP'}}
            ).then(res => {
                // let data = res.data.data[0];
                let data = res.data.data;
                console.log('vac_list', data);

                let area = data.map(el => el.area);
                console.log('area', area);
                let useable = data.map(el => el.usable);
                let tody = data.map(el => el.today);
                this.e7Options.series[0].data = useable;
                this.e7Options.series[0].name = '当日可用疫苗';
                this.e7Options.series[1].data = tody;
                this.e7Options.series[1].name = '当日可接种人数';
                this.e7Options.title.text = '当日可用疫苗/当日可接种人数';
                this.e7Options.xAxis.data = area;
                this.e7Options.legend.data = ['当日可用疫苗', '当日可接种人数'];
                this.$refs.e7.initEcharts();
                
                let today_plan = data.map(el => el.today_plan);
                let tomorrow_plan = data.map(el => el.tomorrow_plan);
                this.barOption.xAxis.data = area;
                this.barOption.series[0].data = today_plan;
                this.barOption.series[0].name = '当日接种计划';
                this.barOption.series[1].data = tomorrow_plan;
                this.barOption.series[1].name = '明日接种计划';
                this.barOption.title.text = '当日接种计划/明日接种计划';
                console.log('barOption', this.barOption);
                this.$refs.barOption.initEcharts();

                let totle = data.map(el => el.totle);
                let yesterday = data.map(el => el.yesterday);
                this.barOption3.xAxis.data = area;
                this.barOption3.series[0].data = totle;
                this.barOption3.series[0].name = '累计接种人数';
                this.barOption3.series[1].data = yesterday;
                this.barOption3.series[1].name = '当日接种总数';
                this.barOption3.title.text = '累计接种人数/当日接种总数';
                this.$refs.barOption3.initEcharts();

            })
        }
    }
}
</script>

<style lang="scss" scoped>
.right_con {
    width: 937px;
    height: 100%;
    margin: 0px 5px 0px 10px;
    .r2 {
        padding: 10px;
        .r_top {
            .r_title {
                font-size: 20px;
                font-weight: 600;
            }
            .r_time {
                font-size: 12px;
                margin: 10px;
            }
        }
        .content {
            display: flex;
            justify-content: space-around;
            .r_tt {
                font-size: 25px;
            }
            .t1 {
                color: '#00faf';
                text-align: left;
                font-weight: 700;
                font-size: 20px;
                line-height: 30px;
            }
            .t_yellow {
                color: yellow;
            }
            .t_green {
                color: green;
            }
            .t_red {
                color: red;
            }
            .row {
                display: flex;
                justify-content: space-around;
            }
            .w50 {
                width: 50%;
            }
        }
    }
    .zhibo {
        .zotitle {
            text-align: left;
            font-size: 24px;
            font-weight: 600;
            padding: 15px;
        }
        .list {
            display: flex;
            justify-content: space-around;
            img {
                border: 1px solid #fff;
            }
        }
    }
    .quyun {
        width: 100%;
        height: 330px;
        border: 1px solid #028ea2;
        margin-bottom: 10px;
        .search {
            display: flex;
        }
        .quyun_swiper {
            position:relative;
            img {
                border: 1px solid #028ea2;
            }
            .active {
                box-shadow: 0px 0px 10px #00faf9;
                border: 1px solid #00faf9;
            }
        }
        .quyun_body {
            padding:0 20px 10px 20px;
            .quyun_body_title {
                font-size: 20px;
                font-weight: 600;
                padding-left:10px;
                button {
                    background-color: #3949ab;
                    padding: 6px 6px 6px 12px;
                    border: 0;
                    border-radius: 2px;
                    font-size: 14px;
                    font-weight: 600;
                    letter-spacing:5px;
                    color: red;
                    margin: 2px;
                    transition: all .4s cubic-bezier(.25,.8,.25,1);
                    cursor: pointer;
                    float: right;
                }
            }
            .quyun_body_time {
                font-size: 12px;
                padding: 6px 0;
                span {
                    color: red;
                }
            }
            .quyun_body_content {
                color: #fff;
                font-weight: 600;
                font-size: 15px;
                line-height: 30px;
            }
        }
        .none {
            font-size: 30px;
            text-align: center;
            color: #00faf9;
            margin: 50px;
        }
    }
}

::v-deep .el-dialog__header {
  padding: 0;
  
}
::v-deep .el-dialog__body {
  padding: 0;
  /*background-color: rgba(3, 27, 63, 0.8);*/
  background-color: #004259;
  color:#00faf9;
}
::v-deep .el-dialog__headerbtn {
  z-index: 9999;
}
::v-deep .el-input__inner {
    border-radius: 0;
    border: 1px solid #00faf9 !important;
    background-color: #003650;
    color: #00faf9;
}
::v-deep .el-input-group__append {
    border-radius: 0;
    border: 1px solid #00faf9 !important;
    background-color: #003650;
    color: #00faf9;
}
</style>